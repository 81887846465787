import { type MetaFunction, useLoaderData } from '@remix-run/react';
import { $path } from 'remix-routes';

import { EnumsPageName } from '@lp-lib/api-service-client/public';

import { useAnonAppAnalytics } from '../../src/analytics/app/anon';
import { PageManagerUtils } from '../../src/components/PageManager/utils';
import config from '../../src/config';
import { FEATURED_GAME_PACKS_SHOW_CAP } from '../components/GamePack/GamePackCollection';
import { MetadataUtils } from '../components/metadata';
import { AnonPublicLibraryIndex } from '../components/PublicLibrary/AnonPublicLibraryIndex';
import { fetchActivePrograms } from '../fetches/fetchActivePrograms';
import { fetchFeatured } from '../fetches/fetchFeatured';
import { fetchGamePacksByTags } from '../fetches/fetchGamePacksByTags';
import { fetchPage } from '../fetches/fetchPage';
import { useExploreData } from './explore';

export const meta: MetaFunction = () => {
  return MetadataUtils.Make({
    title: 'Explore Our Library of Team Building Experiences',
    desc: 'Browse Luna Park’s vast library of virtual team building experiences. Designed for remote & hybrid teams by professional game artists.',
    url: new URL($path('/explore'), config.app.baseUrl).href,
    image: 'generic',
  });
};

export const loader = async () => {
  const page = await fetchPage(EnumsPageName.PageNameExplore);
  const featuredItems = await fetchFeatured(FEATURED_GAME_PACKS_SHOW_CAP);
  const gamePackTags = PageManagerUtils.GetGamePackTags(page);
  const groupedGamePacks = await fetchGamePacksByTags(
    gamePackTags.map((t) => t.id)
  );
  const programs = await fetchActivePrograms();

  return {
    programs,
    featuredItems,
    groupedGamePacks,
  };
};

export function shouldRevalidate() {
  return false;
}

export default function Component() {
  const { pageName, page, product } = useExploreData();
  const data = useLoaderData<typeof loader>();

  const analytics = useAnonAppAnalytics();

  return (
    <AnonPublicLibraryIndex
      product={product}
      pageName={pageName}
      page={page}
      {...data}
      analytics={analytics}
    />
  );
}
